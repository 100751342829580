import {
    of as observableOf,
    from as observableFrom,
    Observable,
    Subscriber,
    throwError as observableThrowError,
} from 'rxjs';
import { ajax as rxAjax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';

import { IObject } from '../../types';
import { getXSRFToken } from '../cookie';

import { ajax, NetworkRequestConfig, RequestType } from './ajax';
import { transformResponseToJson } from './helpers';

export function sendRetrieveRequest(
    url: string,
    overrides: Partial<NetworkRequestConfig> = { headers: {} },
): Observable<AjaxResponse<any>> {
    return ajaxWithError({ url, method: 'GET', ...overrides });
}

export function sendTextRequest(
    url: string,
    overrides: Partial<NetworkRequestConfig> = { headers: {} },
): Observable<AjaxResponse<any>> {
    // we do not want to transform back to json using the ajaxWithError
    return ajax({
        url,
        method: 'GET',
        responseType: 'text',
        ...overrides,
    });
}

export function sendFileRequest(
    url: string,
    overrides: Partial<NetworkRequestConfig> = { headers: {} },
): Observable<AjaxResponse<any>> {
    return ajax({
        url,
        method: 'GET',
        responseType: 'arraybuffer',
        ...overrides,
    });
}

export function sendDeleteRequestWithXSRFToken(
    url: string,
    data?: IObject,
    overrides: Partial<NetworkRequestConfig> = { headers: {} },
): Observable<AjaxResponse<any>> {
    const baseBody = { url, method: 'DELETE', ...overrides } as NetworkRequestConfig;
    const body = data ? { ...baseBody, payload: data, contentType: 'json' } : baseBody;
    body.headers = { 'X-Xsrftoken': getXSRFToken() };
    return ajaxWithError(body);
}

export function sendDeleteRequest(
    url: string,
    data?: IObject,
    overrides: Partial<NetworkRequestConfig> = { headers: {} },
): Observable<AjaxResponse<any>> {
    const baseBody = { url, method: 'DELETE', ...overrides } as NetworkRequestConfig;
    const body = data ? { ...baseBody, payload: data, contentType: 'json' } : baseBody;
    return ajaxWithError(body);
}

export function sendRetrieveRequestWithAcceptHeader(
    url: string,
    acceptHeader: string,
): Observable<AjaxResponse<any>> {
    return ajax({
        url,
        method: 'GET',
        headers: {
            Accept: acceptHeader,
        },
    });
}

/**
 * Make a cross-origin HTTP `GET` request.
 *
 * @param url The URL to retrieve
 *
 * [The `withCredentials` option is set to `false`](https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS/Errors/CORSNotSupportingCredentials)
 * because of CORS.
 */
export function sendRetrieveRequestCrossDomain(url: string): Observable<AjaxResponse<any>> {
    const config: NetworkRequestConfig = {
        url,
        method: 'GET',
        crossDomain: true,
        withCredentials: false,
    };
    return ajaxWithError(config);
}

export function sendUpdateRequest(
    url: string,
    data: IObject,
    overrides: Partial<NetworkRequestConfig> = { headers: {} },
): Observable<AjaxResponse<any>> {
    return ajaxWithError({
        url,
        method: 'PATCH',
        payload: data,
        contentType: 'json',
        ...overrides,
    });
}

export function sendReplaceRequestCrossDomain(
    url: string,
    data: IObject | string,
    contentType: string,
    overrides: Partial<NetworkRequestConfig> = { headers: {} },
): Observable<AjaxResponse<any>> {
    return ajax({
        url,
        contentType,
        method: 'PUT',
        payload: data,
        responseType: 'text',
        crossDomain: true,
        ...overrides,
    });
}

export function sendReplaceRequest(
    url: string,
    data: IObject,
    overrides: Partial<NetworkRequestConfig> = { headers: {} },
): Observable<AjaxResponse<any>> {
    return ajaxWithError({
        url,
        method: 'PUT',
        payload: data,
        contentType: 'json',
        ...overrides,
    });
}

export function sendCreateRequest(
    url: string,
    data: IObject | string,
    contentType = 'json',
    overrides: Partial<NetworkRequestConfig> = { headers: {} },
): Observable<AjaxResponse<any>> {
    return ajaxWithError({
        url,
        contentType,
        method: 'POST',
        payload: data,
        ...overrides,
    });
}

export function sendRequestWithXSRFToken(
    url: string,
    data?: IObject | string,
    method: RequestType = 'POST',
): Observable<AjaxResponse<any>> {
    return ajaxWithError({
        url,
        contentType: 'json',
        method,
        payload: data,
        headers: { 'X-Xsrftoken': getXSRFToken() },
    });
}

export function sendFileDownloadRequest(url: string): Observable<Blob> {
    return observableFrom(fetch(url).then((response) => response.blob()));
}

export function sendFileInBodyUploadRequestWithXSRFTokenCatchErrors(
    url: string,
    data: { file: File },
): Observable<AjaxResponse<any>> {
    let contentType = data.file.type;
    if (data.file.type === '') {
        contentType = 'application/octet-stream';
    } else if (data.file.type === 'application/json') {
        contentType = 'text/plain';
    }

    // Using browser's fetch API. Better solution is to use ajaxCatchError. RxJS ajax not working
    // with tornado python backend. Once we find a fix for that we should replace.
    const headers = new Headers();
    headers.append('X-Xsrftoken', getXSRFToken() ?? '');

    const requestOptions = {
        method: 'POST',
        contentType,
        headers,
        body: data.file,
    };

    return observableFrom(
        fetch(url, requestOptions).then((response) => (!response.ok ? response : response.json())),
    );
}

export const sendFileUploadRequestWithXSRFTokenCatchErrors = (
    url: string,
    data: Record<string, string | Blob>,
) => {
    const form = new FormData();

    Object.entries(data).forEach(([key, value]) => form.append(key, value));

    // Using browser's fetch API. Better solution is to use ajaxCatchError. RxJS ajax not working
    // with tornado python backend. Once we find a fix for that we should replace.
    const headers = new Headers();
    headers.append('X-Xsrftoken', getXSRFToken() ?? '');

    const requestOptions = {
        method: 'POST',
        contentType: 'multipart/form-data',
        headers,
        body: form,
    };

    return observableFrom(
        fetch(url, requestOptions).then((response) => (!response.ok ? response : response.json())),
    );
};

export function sendRetrieveArrayBufferRequest(url: string): Observable<AjaxResponse<any>> {
    return ajax({
        url,
        method: 'GET',
        responseType: 'arraybuffer',
        crossDomain: true,
    });
}

export function sendReplaceImageRequestCrossDomain(
    url: string,
    data: Blob,
): Observable<AjaxResponse<any>> {
    return ajax({
        url,
        method: 'PUT',
        payload: data,
        contentType: 'png',
        crossDomain: true,
    });
}

export function sendRetrieveBlobRequest(url: string): Observable<AjaxResponse<any>> {
    return ajax({
        url,
        method: 'GET',
        responseType: 'blob',
        crossDomain: true,
    });
}

export function sendSafeCreateRequest(
    url: string,
    payload: Record<string, any>,
): Observable<AjaxResponse<any>> {
    return ajaxWithError({
        payload,
        url,
        method: 'POST',
        contentType: 'json',
    });
}

// NB: the progressSubscriber is breaking when used via the wrapped ajax
// to work around this issue for the time being, we're using the native rxjs ajax function here
export const ajaxPutWithProgress = (
    url: string,
    file: File,
    progressSubscriber: Subscriber<any>,
    extras: Record<string, any> = {},
): Observable<AjaxResponse<any>> => {
    const nonEmptyContent = file.type === 'application/json' ? 'text/plain' : file.type;
    const request = {
        url,
        progressSubscriber,
        headers: {
            // `file.type` can be an empty string, in which case rxjs may set it to something we don't want WEB-1873.
            // .json files will try send request headers as application/json. but this fails to send the actual file
            // try removing this 'string hack' when upgrading RXjs to 7.x.x as issue may have been fixed
            'Content-Type': file.type === '' ? 'application/octet-stream' : nonEmptyContent,
        },
        method: 'PUT',
        body: file,
        ...extras,
    };
    return rxAjax(request);
};

export const ajaxPutFileWithProgress = (
    url: string,
    file: File,
    progressSubscriber: Subscriber<any>,
    extras: Record<string, any> = {},
): Observable<AjaxResponse<any>> => {
    const formData = new FormData();
    formData.append('file', file);

    const request = {
        url,
        progressSubscriber,
        method: 'PUT',
        body: formData,
        ...extras,
    };
    return rxAjax(request);
};

export function ajaxWithError(config: NetworkRequestConfig): Observable<AjaxResponse<any>> {
    return ajax({ ...config, responseType: 'text' }).pipe(
        map(transformResponseToJson),
        catchError((r: Observable<AjaxError>) =>
            observableThrowError(() => transformResponseToJson(r)),
        ),
    );
}

export function ajaxCatchError(config: NetworkRequestConfig): Observable<AjaxResponse<any>> {
    return ajax({ ...config, responseType: 'text' }).pipe(
        map(transformResponseToJson),
        catchError((r: Observable<AjaxError>) => observableOf(transformResponseToJson(r))),
    );
}
